<template>
  <div class="partner-expense-item-professionals">
    <template v-if="professionalItems.length > 0">
      <div class="partner-expense-item-professional"
           v-for="(item, n) in professionalItems" :key="n">
        <professional-item
          :idx="n"
          :item="item"
          :partner-expense-id="partnerExpenseId"
          :expense="expense"
          @removeProfessional="removeProfessional"
        />
      </div>
      <div class="divider" />
      <button class="btn btn-gray btn-sm mr-1"
              @click="openNewProfessional">
        Adicionar profissional
      </button>
      <button class="btn btn-gray btn-sm"
              v-if="professionalItems.length > 1"
              @click="openBulkChangeModal">
        Atualização em massa
      </button>
    </template>
    <div v-else>
      <div class="empty">
        <p class="empty-title h6">Profissionais</p>
        <p class="empty-subtitle">
          Nenhum profissional adicionado. Clique no botão abaixo para adicionar
        </p>
        <button class="btn btn-primary btn-sm mt-2"
                @click="openNewProfessional">
          Adicionar profissional
        </button>
      </div>
    </div>
    <dx-modal title="Adicionar profissional"
              v-model="newProfessional.show"
              id="modal-waiting-list">
      <div class="empty" v-if="filteredProfessionals.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Profissionais</p>
        <p class="empty-subtitle">
          Nenhum profissional disponível para este procedimento
        </p>
      </div>
      <table class="table table-striped table-hover c-hand" v-else>
        <thead>
        <tr>
          <th>#</th>
          <th>Nome</th>
          <th>Especialidade</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(professional, i) in filteredProfessionals"
            :key="i" @click="addProfessional(professional)">
          <td>{{ i + 1}}</td>
          <td>{{ professional.name }}</td>
          <td>{{ professional.specialty.name }}</td>
          <td class="text-right"><fa-icon :icon="['fal', 'chevron-right']"/></td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="newProfessional.show = false">Sair</button>
      </template>
    </dx-modal>
    <dx-modal title="Alteração de valores em massa"
              v-model="bulkChangeModal.show" size="sm"
              id="modal-waiting-list">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="bulk-expense" class="form-label">Procedimento</label>
          <input id="bulk-expense" type="text" class="form-input text-bold"
                 v-model="expense.name" readonly>
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.bulkChangeModal.cost.$error}">
          <label class="form-label">Preço de custo</label>
          <dx-input-number class="form-input text-right"
                           v-model=bulkChangeModal.cost
                           @blur="$v.bulkChangeModal.cost.$touch()"
                           :precision="2" />
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.bulkChangeModal.final.$error}">
          <label class="form-label">Preço de venda</label>
          <dx-input-number class="form-input text-right"
                           v-model=bulkChangeModal.final
                           @blur="$v.bulkChangeModal.final.$touch()"
                           :precision="2" />
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :class="{loading: bulkChangeModal.saving}"
                :disabled="bulkChangeModal.saving"
                @click="save">Atualizar</button>
        <button class="btn" @click="bulkChangeModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/src/validators';
import professionalItem from './ExpenseProfessionalItem.vue';

export default {
  components: {
    professionalItem,
  },
  props: {
    partnerId: {
      type: String,
    },
    partnerExpenseId: {
      type: String,
    },
    expense: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
    },
    professionalItems: {
      type: Array,
    },
  },
  data() {
    return {
      professionals: [],
      newProfessional: {
        saving: false,
        show: false,
      },
      bulkChangeModal: {
        cost: '',
        final: '',
        show: false,
      },
    };
  },
  computed: {
    filteredProfessionals() {
      return this.professionals
        .filter(item => (
          !this.professionalItems.some(professional => (
            professional.id === item.id
            && professional.specialty.code === item.specialty.code
          ))
        ))
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
    },
  },
  validations() {
    return {
      bulkChangeModal: {
        cost: { required, minValue: minValue(0.01) },
        final: {
          required,
          minValue: minValue(this.bulkChangeModal.cost
            ? this.bulkChangeModal.cost
            : 0.01),
        },
      },
    };
  },
  methods: {
    openNewProfessional() {
      if (this.professionals.length === 0) {
        this.loadProfessionals();
      }
      this.newProfessional.show = true;
    },
    openBulkChangeModal() {
      this.$v.bulkChangeModal.$reset();
      this.bulkChangeModal.cost = '';
      this.bulkChangeModal.final = '';
      this.bulkChangeModal.show = true;
    },
    addProfessional(item) {
      this.newProfessional.saving = true;
      const data = {
        professionalId: item.id,
        specialtyCode: item.specialty.code,
      };

      this.$http.post(
        `/partner-expenses/${this.partnerExpenseId}/professionals`, data,
      )
        .then(({ data: result }) => {
          this.professionalItems.push(result);
          this.newProfessional.show = false;
        })
        .catch(() => {})
        .then(() => {
          this.newProfessional.saving = false;
        });
    },
    removeProfessional(item) {
      const found = this.professionalItems
        .find(professional => professional.id === item.professionalId
          && professional.specialty.code === item.specialtyCode);

      if (found) {
        const idx = this.professionalItems.indexOf(found);
        this.professionalItems.splice(idx, 1);
      }
    },
    loadProfessionals() {
      return this.$http.get(`/partners/${this.partnerId}/professionals`)
        .then(({ data }) => {
          data.items.forEach((professional) => {
            professional.specialties.forEach((specialty) => {
              this.professionals.push({
                id: professional.id,
                name: professional.name,
                specialty: {
                  code: specialty.code,
                  name: specialty.name,
                },
              });
            });
          });
        })
        .catch();
    },
    save() {
      this.$v.bulkChangeModal.$touch();
      if (this.$v.bulkChangeModal.$error) {
        return false;
      }

      this.bulkChangeModal.saving = true;

      const data = {
        cost: this.bulkChangeModal.cost,
        final: this.bulkChangeModal.final,
      };

      return this.$http.post(`/partner-expenses/${this.partnerExpenseId}/bulk-values`, data)
        .then(() => {
          this.professionalItems.forEach((item) => {
            item.price.cost = data.cost;
            item.price.final = data.final;
          });
        })
        .finally(() => {
          this.bulkChangeModal.saving = false;
          this.bulkChangeModal.show = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-expense-item-professionals {
    background-color: $light-color;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-sm;
    padding: $layout-spacing;
    .partner-expense-item-professional {
      padding: 0 $layout-spacing-sm;
    }
    .divider {
      margin: 0 0 $layout-spacing 0;
    }
    .empty {
      background-color: $light-color;
      padding: $layout-spacing-lg;
    }
  }
</style>
