<template>
  <st-side-page class="partner-page">
    <portal to="page-name">Cadastro de parceiro</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <template v-else>
      <template slot="side">
        <div class="partner-image" :style="bgImage"
             :class="{'show-options': showImageOptions}">
          <div class="partner-image-options">
            <template v-if="bgImage">
              <button class="btn btn-icon btn-error tooltip" data-tooltip="Remover logo"
                      @click="removeImage()">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </template>
            <template v-else>
              <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar logo"
                      @click="image.showOptions = true">
                <fa-icon :icon="['fal', 'upload']"/>
                <input type="file" name="image" accept="image/*"
                       @change="loadImage">
              </button>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-12 form-group mt-2">
            <button class="btn btn-block btn-primary" @click="savePartner"
                    :disabled="saving" :class="{loading: saving}">
              Salvar
            </button>
          </div>
          <div class="column col-12 form-group">
            <button class="btn btn-block" @click="$router.back()">
              Voltar
            </button>
          </div>
        </div>
      </template>
      <div class="card">
        <div class="card-header">
          <div class="form-group float-right status-toggle">
            <label class="form-switch">
              <input type="checkbox" v-model="form.partner.active">
              <i class="form-icon"/> Registro ativo
            </label>
          </div>
          <div class="card-title">
            Cadastro de parceiro
            <span v-if="form.name"> - </span>
            <small class="text-primary"> {{ form.name }}</small>
          </div>
        </div>
        <div class="card-body">
          <st-tabs ref="partner-tabs">
            <st-tab id="tab-partner" name="Dados principais">
              <div class="columns">
                <div class="column col-12 mt-2">
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="form.type" value="company"
                           :disabled="!!form.id">
                    <i class="form-icon"/> Pessoa Jurídica
                  </label>
                  <label class="form-radio form-inline">
                    <input type="radio" name="type" v-model="form.type" value="person"
                           :disabled="!!form.id">
                    <i class="form-icon"/> Pessoa Física
                  </label>
                </div>
                <template v-if="form.type === 'company'">
                  <div class="column col-3 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.identity.value.$error}">
                    <label for="company-cnpj" class="form-label">CNPJ</label>
                    <input type="text" id="company-cnpj" name="company-cnpj"
                           v-model="form.identity.value" :disabled="!!form.id"
                           @blur="$v.form.identity.value.$touch()"
                           key="company-cnpj" @change="loadEntityPartner"
                           class="form-input" autocomplete="nope"
                           placeholder="00.000.000/0000-00" v-mask-cnpj>
                    <template v-if="$v.form.identity.value.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.identity.value.cnpj">CNPJ inválido</div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="column col-3 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.identity.value.$error}">
                    <label for="company-cpf" class="form-label">CPF</label>
                    <input type="text" id="company-cpf" name="company-cpf" key="company-cpf"
                           v-model="form.identity.value" :disabled="!!form.id"
                           @blur="$v.form.identity.value.$touch()"
                           class="form-input" autocomplete="nope" @change="loadEntityPartner"
                           placeholder="000.000.000-00" v-mask-cpf>
                    <template v-if="$v.form.identity.value.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.identity.value.cpf">CPF inválido</div>
                    </template>
                  </div>
                </template>
                <div class="column col-4 col-md-4 col-sm-12 form-group"
                     :class="{'has-error': $v.form.name.$error}">
                  <label for="company-name" class="form-label">Nome</label>
                  <input type="text" id="company-name" name="company-name"
                         v-model="form.name" class="form-input"
                         @blur="$v.form.name.$touch()"
                         :disabled="!!form.id && form.type === 'person'"
                         placeholder="Nome" autocomplete="nope">
                  <template v-if="$v.form.name.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.name.required">Campo obrigatório</div>
                  </template>
                </div>
                <template v-if="form.type === 'company'">
                  <div class="column col-5 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.companyName.$error}">
                    <label for="corporate-name" class="form-label">Razão Social</label>
                    <input type="text" id="corporate-name" name="corporate-name"
                           v-model="form.companyName" class="form-input" :disabled="!!form.id"
                           key="corporate-name" @blur="$v.form.companyName.$touch()"
                           placeholder="Nome" autocomplete="nope">
                    <template v-if="$v.form.companyName.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.companyName.required">Campo obrigatório</div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="column col-3 col-sm-12 form-group"
                       :class="{'has-error': $v.form.birthDate.$error}">
                    <label for="person-birth-date" class="form-label">Nascimento</label>
                    <input type="text" id="person-birth-date" name="person-birth-date"
                           v-model="form.birthDate" class="form-input" :disabled="!!form.id"
                           @blur="$v.form.birthDate.$touch()" key="person-birth-date"
                           placeholder="dd/mm/aaaa" v-mask-date.br>
                    <template v-if="$v.form.birthDate.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.birthDate.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.birthDate.date">Data inválida</div>
                    </template>
                  </div>
                  <div class="column col-2 col-sm-12 form-group"
                       :class="{'has-error': $v.form.gender.$error}">
                    <label for="person-gender" class="form-label">Sexo</label>
                    <select id="person-gender" name="person-gender"
                            :disabled="!!form.id && !!form.gender"
                            v-model="form.gender" key="person-gender"
                            class="form-select" @change="$v.form.gender.$touch()">
                      <option value="">[Selecione]</option>
                      <option value="female">Feminino</option>
                      <option value="male">Masculino</option>
                    </select>
                    <template v-if="$v.form.gender.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.gender.required">Campo obrigatório</div>
                    </template>
                  </div>
                </template>
              </div>
              <div class="columns">
                <div class="column col-3 col-md-4 col-sm-12 form-group"
                     :class="{'has-error': $v.form.phone.$error}">
                  <label for="phone" class="form-label">Telefone</label>
                  <input type="text" id="phone" name="phone" v-model="form.phone"
                         class="form-input" @blur="$v.form.phone.$touch()" maxlength="14"
                         placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
                  <template v-if="$v.form.phone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.phone.required">Campo obrigatório</div>
                    <div class="form-input-hint"
                         v-if="!$v.form.phone.phone">Telefone inválido</div>
                  </template>
                </div>
                <div class="column col-3 col-md-4 col-sm-12 form-group"
                     :class="{'has-error': $v.form.cellphone.$error}">
                  <label for="cellphone" class="form-label">Celular</label>
                  <input type="text" id="cellphone" name="cellphone"
                         v-model="form.cellphone" class="form-input" autocomplete="nope"
                         @blur="$v.form.cellphone.$touch()"
                         placeholder="(00) 0 0000-0000" v-mask-phone.br>
                  <template v-if="$v.form.cellphone.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.cellphone.phone">Telefone inválido</div>
                  </template>
                </div>
                <div class="column col-6 col-md-4 col-sm-12 form-group"
                     :class="{'has-error': $v.form.email.$error}">
                  <label for="email" class="form-label">Email</label>
                  <input type="text" id="email" name="email" v-model="form.email"
                         class="form-input" @blur="$v.form.email.$touch()"
                         placeholder="nome@email.com" autocomplete="nope">
                  <template v-if="$v.form.email.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.email.email">Email inválido</div>
                  </template>
                </div>
              </div>
              <div class="text-gray">Endereço</div>
              <div class="columns">
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-cep" class="form-label">CEP</label>
                  <div class="input-group">
                    <input type="text" id="address-cep" name="address-cep"
                           class="form-input" v-model="address.postalCode"
                           placeholder="00.000-000" @change="loadAddress"
                           v-mask-cep autocomplete="nope">
                    <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                      :disabled="loadingAddress" :class="{loading: loadingAddress}">
                      <fa-icon :icon="['fal', 'search']"/>
                    </button>
                  </div>
                </div>
                <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
                <div class="column col-9 col-md-12 col-sm-12 form-group">
                  <label for="address-addressLine1" class="form-label">Logradouro</label>
                  <input type="text" id="address-addressLine1" name="address-addressLine1"
                         class="form-input" v-model="address.addressLine1"
                         placeholder="Avenida Brasil, 1000" autocomplete="nope">
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-addressLine2" class="form-label">Complemento</label>
                  <input type="text" id="address-addressLine2" name="address-addressLine2"
                         class="form-input" v-model="address.addressLine2"
                         placeholder="casa, sobrado, loja..." autocomplete="nope">
                </div>
                <div class="column col-5 col-md-6 col-sm-12 form-group">
                  <label for="address-neighborhood" class="form-label">Bairro</label>
                  <input type="text" id="address-neighborhood" name="address-neighborhood"
                         placeholder="Bairro" v-model="address.neighborhood"
                         class="form-input" autocomplete="nope">
                </div>
                <div class="column col-4 col-md-6 col-sm-12 form-group">
                  <label for="address-city" class="form-label">Cidade</label>
                  <input type="text" id="address-city" name="address-city"
                         class="form-input" v-model="address.city"
                         placeholder="Cidade" autocomplete="nope">
                </div>
                <div class="column col-3 col-md-6 col-sm-12 form-group">
                  <label for="address-state" class="form-label">Estado</label>
                  <select id="address-state" name="address-state"
                         class="form-select" v-model="address.state">
                    <option value="">[Selecione]</option>
                    <option v-for="(text, value) in states"
                            :value="value" :key="value">{{ value }}</option>
                  </select>
                </div>
              </div>
              <div class="text-gray">Notas</div>
              <div class="columns">
                <div class="column col-12 form-group">
                  <label for="notes" class="form-label">Observações</label>
                  <textarea id="notes" name="notes" class="form-input" rows="4"
                            v-model="form.notes"/>
                </div>
              </div>
            </st-tab>
            <st-tab id="tab-professionals" name="Profissionais" v-if="form.id">
              <professional-form
                v-if="form.id"
                :partner-id="form.id"
                @removeProfessional="removeProfessional" />
            </st-tab>
            <st-tab id="tab-expenses" name="Procedimentos" v-if="form.id">
              <expense-form
                ref="partnerExpenses"
                v-if="form.id"
                :partner-id="form.id" />
            </st-tab>
          </st-tabs>
        </div>
      </div>
    </template>
  </st-side-page>
</template>

<script>
import entityMixin from 'src/mixins/entity';
import { required } from 'vuelidate/src/validators';
import { cpf, cnpj } from 'src/data/validators';
import expenseForm from './Forms/Expense.vue';
import professionalForm from './Forms/Professional.vue';

export default {
  components: {
    expenseForm,
    professionalForm,
  },
  mixins: [entityMixin],
  data() {
    return {
      type: 'partner',
      path: '/partners',
      form: {},
      menus: [],
      image: this.imageFormBlank(),
    };
  },
  created() {
    this.form = this.blankForm();
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    this.form.type = 'company';

    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load(this.form.id, this.path);
    }
  },
  validations() {
    return this.entityValidationRules((rules) => {
      const localRules = rules;

      if (this.form.type === 'person') {
        localRules.form.identity = {
          value: { required, cpf },
        };
      } else {
        localRules.form.identity = {
          value: {
            required,
            cnpj,
          },
        };
      }

      return localRules;
    });
  },
  computed: {
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
  },
  methods: {
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    removeImage() {
      this.image = this.imageFormBlank();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    loadEntityPartner() {
      this.loadEntityByIdentity(this.path);
    },
    savePartner() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.address.type = 'business';

      this.save(this.path)
        .then((data) => {
          if (/create$/.test(this.$route.path)) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          if (this.image.file) {
            const formData = new FormData();
            formData.append('file', this.image.file);
            this.$http.post(`/entities/${data.id}/images`, formData)
              .then(() => {
                this.image.file = null;
              })
              .catch(() => {});
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    removeProfessional() {
      this.$refs.partnerExpenses.load();
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-page {
    &.side-page .side-page-side {
      flex: 0 0 10.1rem;
      dd {
        margin-top: 0;
      }
    }
    .partner-image {
      margin-top: $layout-spacing;
      background: $gray-color-light center no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      height: 9rem;
      width: 9rem;
      &:not(.show-options):hover {
        .partner-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .partner-image-options {
          opacity: 1;
        }
      }
    }
    .partner-image-options {
      align-items: center;
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
    .panel {
      background-color: #fff;
    }
  }
</style>
