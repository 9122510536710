<template>
  <div class="partner-expense-page">
    <div class="loading loading-lg mt-2" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento cadastrado. Clique no botão abaixo para cadastrar.
        </p>
        <button class="btn btn-primary btn-icon-left mt-2"
                @click="openExpenseModal">
          <fa-icon :icon="['fal', 'plus-circle']" />
          Adicionar novo procedimento
        </button>
      </div>
      <div v-else>
        <button class="btn btn-primary btn-icon-left mt-2"
                @click="openExpenseModal">
          <fa-icon :icon="['fal', 'plus-circle']" />
          Adicionar novo procedimento
        </button>
        <div class="mt-2 text-bold">Lista de procedimentos</div>
        <expense-item
          v-for="(item, i) in items" :key="i"
          :partner-id="partnerId"
          :item="item"
          @removeExpense="removeExpense"
        />
      </div>
    </template>
    <dx-modal title="Procedimentos"
              v-model="expenseModal.show"
              id="modal-waiting-list">
      <div class="columns">
        <div class="column form-group">
          <label for="expense-search" class="form-label">Filtrar</label>
          <div class="input-group">
            <input type="text" id="expense-search" class="form-input"
                   v-model="expenseModal.search"
                   maxlength="100" placeholder="Código ou parte do nome">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    :class="{loading: expenseModal.loading}"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']" v-if="!expenseModal.loading"/>
            </button>
          </div>
        </div>
      </div>
      <div class="empty mt-2" v-if="expenseModal.items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Nenhum procedimento encontrado.
        </p>
      </div>
      <table class="table table-striped table-hover c-hand" v-else>
        <thead>
        <tr>
          <th width="100px">Código</th>
          <th>Nome</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(expense, i) in filteredExpenses" :key="i"
            @click="addExpense(expense)">
          <td>{{ expense.tuss ? expense.tuss.code : '' | tuss }}</td>
          <td>{{ expense.name }}</td>
          <td class="text-right"><fa-icon :icon="['fal', 'chevron-right']"/></td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="expenseModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import expenseItem from './ExpenseItem.vue';

export default {
  components: {
    expenseItem,
  },
  props: {
    partnerId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      expenseModal: {
        show: false,
        loading: false,
        saving: false,
        search: '',
        items: [],
      },
      items: [],
    };
  },
  computed: {
    filteredExpenses() {
      return this.expenseModal.items
        .filter(item => this.items
          .every(({ expense }) => expense.id !== item.id));
    },
  },
  watch: {
    'expenseModal.search': {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.loadExpenses, 700);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      const params = {
        partnerId: this.partnerId,
      };
      return this.$http.get('/partner-expenses', { params })
        .then(({ data }) => {
          this.items = data.items.map((item) => {
            item.price = {
              cost: 0,
              final: 0,
            };
            if (item.expense.type === 'laboratory'
              && item.professionals
              && item.professionals.length > 0) {
              item.price = item.professionals[0].price;
            }
            return item;
          });
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    loadExpenses() {
      this.expenseModal.loading = true;
      const params = {
        limit: 50,
        active: true,
      };

      if (this.expenseModal.search) {
        params.search = this.expenseModal.search;
      }

      return this.$http.get('/expenses', { params })
        .then(({ data }) => {
          this.expenseModal.items = data.items;
        })
        .catch()
        .finally(() => {
          this.expenseModal.loading = false;
        });
    },
    openExpenseModal() {
      if (this.expenseModal.items.length === 0) {
        this.loadExpenses();
      }
      this.expenseModal.show = true;
    },
    addExpense(item) {
      this.expenseModal.saving = true;
      const data = {
        expenseId: item.id,
        partnerId: this.partnerId,
      };

      this.$http.post('/partner-expenses', data)
        .then(({ data: expense }) => {
          expense.price = {
            cost: 0,
            final: 0,
          };
          this.items.push(expense);
          this.expenseModal.show = false;
        })
        .catch(() => {})
        .then(() => {
          this.expenseModal.saving = false;
        });
    },
    removeExpense(expenseId) {
      const item = this.items.find(({ id }) => id === expenseId);
      const idx = this.items.indexOf(item);
      this.items.splice(idx, 1);
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-expense-page {
  }
</style>
