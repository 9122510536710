<template>
  <div class="partner-expense-item">
    <div class="columns">
      <div class="column centered">
          <span class="text-dark hover-link tooltip tooltip-right"
                @click="openManagementModal(item)"
                data-tooltip="Definir regras do procedimento">
            <span v-if="item.expense.tuss && item.expense.tuss.code">
              {{ item.expense.tuss.code | tuss }} -
            </span> {{ item.expense.name }}
          </span>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 text-right"
           v-if="item.expense.type === 'laboratory'">
          <span class="tooltip tooltip-left" data-tooltip="Preço de custo">
            <dx-input-number class="form-input text-right input-sm"
                             v-model="item.price.cost" :precision="2" />
          </span>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 text-right"
           v-if="item.expense.type === 'laboratory'">
          <span class="tooltip tooltip-left" data-tooltip="Preço de venda">
            <dx-input-number class="form-input text-right input-sm"
                             v-model="item.price.final" :precision="2" />
          </span>
      </div>
      <div class="column col-auto"
           v-if="item.expense.type === 'laboratory'">
        <button class="btn btn-sm btn-icon"
                :class="{
                    loading: saving,
                    'btn-neutral': !dirty,
                    'btn-primary': dirty,
                  }"
                :disabled="saving || !dirty"
                @click="save">
          <fa-icon :icon="['fal', 'save']"/>
        </button>
      </div>
    </div>
    <expense-professional-items
      v-if="item.expense.type !== 'laboratory'"
      class="mt-1"
      :partner-id="partnerId"
      :partner-expense-id="item.id"
      :expense="item.expense"
      :professionalItems="item.professionals"
    />
    <dx-modal title="Gerenciar procedimento"
              v-model="managementModal.show"
              id="modal-waiting-list">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="management-expense-name" class="form-label">Procedimento</label>
          <input id="management-expense-name" type="text" readonly
                 class="form-input text-bold" v-model="managementModal.expenseName">
        </div>
        <div class="column col-12 form-group">
          <label for="management-notes" class="form-label">Instruções / Preparo</label>
          <textarea id="management-notes" name="notes"
                    class="form-input" rows="10"
                    v-model="managementModal.notes" />
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-error float-left"
                :class="{loading: managementModal.deleting}"
                :disabled="managementModal.deleting || managementModal.saving"
                @click="removeExpense">
          Remover procedimento
        </button>
        <button class="btn btn-primary mr-1"
                :disabled="managementModal.saving || managementModal.deleting"
                :class="{loading: managementModal.saving}"
                @click="saveManagementModal">Salvar</button>
        <button class="btn" @click="managementModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import expenseProfessionalItems from './ExpenseProfessionalItems.vue';

export default {
  components: {
    expenseProfessionalItems,
  },
  props: {
    partnerId: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      dirty: false,
      professionals: [],
      managementModal: {
        id: '',
        expenseName: '',
        notes: '',
        saving: false,
        deleting: false,
        show: false,
      },
    };
  },
  watch: {
    'item.price': {
      handler() {
        this.dirty = true;
      },
      deep: true,
    },
  },
  methods: {
    openManagementModal(item) {
      this.managementModal.id = item.id;
      this.managementModal.expenseName = item.expense.name;
      this.managementModal.notes = item.expense.notes;
      this.managementModal.show = true;
    },
    saveManagementModal() {
      this.managementModal.saving = true;

      const data = {
        notes: this.managementModal.notes,
      };

      this.$http.put(`/partner-expenses/${this.managementModal.id}`, data)
        .then(() => {
          const found = this.items.find(({ id }) => id === this.managementModal.id);
          if (found) {
            found.expense.notes = data.notes;
          }
        })
        .catch(() => {})
        .then(() => {
          this.managementModal.show = false;
          this.managementModal.saving = false;
        });
    },
    removeExpense() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este procedimento?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.managementModal.deleting = true;
              this.$http
                .delete(`/partner-expenses/${this.managementModal.id}`)
                .then(() => {
                  close();
                  this.$emit('removeExpense', this.managementModal.id);
                  this.managementModal.show = false;
                })
                .catch(() => {
                  this.$toast.show('Ocorreu um erro', { type: 'error' });
                })
                .then(() => {
                  this.managementModal.deleting = false;
                });
            },
          },
        ],
      });
    },
    loadProfessionals() {
      return this.$http.get(`/partners/${this.partnerId}/professionals`)
        .then(({ data }) => {
          this.professionals = data.items;
        })
        .catch();
    },
    async save() {
      await this.loadProfessionals();

      const hasProfessional = this.item.professionals && this.item.professionals.length > 0;

      if (!hasProfessional && this.professionals.length === 0) {
        this.$toast.show('Obrigatorio cadastrar um profissional!', { type: 'error ' });
        return;
      }

      const [professional] = hasProfessional
        ? this.item.professionals
        : this.professionals;

      const specialtyCode = hasProfessional
        ? professional.specialty.code
        : professional.specialties[0].code;

      const data = {
        professionalId: professional.id,
        specialtyCode,
        price: this.item.price,
      };

      const request = hasProfessional
        ? this.$http.put(`/partner-expenses/${this.item.id}/professionals`, data)
        : this.$http.post(`/partner-expenses/${this.item.id}/professionals`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.item.professionals
            .find(item => item.id === result.id
              && item.specialty.code === result.specialty.code);
          if (found) {
            found.price = data.price;
          } else {
            this.item.professionals.push(result);
          }
          this.dirty = false;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-expense-item {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    margin-top: $layout-spacing;
    padding: $layout-spacing;
  }
</style>
