<template>
  <div class="partner-expense-professional-item">
    <div class="columns">
      <div class="column col-1 form-group">{{ idx + 1 }}</div>
      <div class="column col-md-11 col-sm-11 form-group">
        <span class="text-primary hover-link tooltip"
              data-tooltip="Definir regras do profissional"
              @click="openProfessional">
          {{ item.name }}
        </span>
        <small> ({{ item.specialty.name }})</small>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 text-right form-group">
        <span class="tooltip tooltip-left" data-tooltip="Preço de custo">
          <dx-input-number class="form-input text-right input-sm"
                           v-model="professional.price.cost"
                           :precision="2" />
        </span>
      </div>
      <div class="column col-2 col-md-12 col-sm-12 text-right form-group">
        <span class="tooltip tooltip-left" data-tooltip="Preço de venda">
          <dx-input-number class="form-input text-right input-sm"
                           v-model="professional.price.final"
                           :precision="2" />
        </span>
      </div>
      <div class="column col-auto form-group">
        <button class="btn btn-sm btn-icon"
                :class="{
                  loading: professionalRuleModal.saving,
                  'btn-neutral': !dirty,
                  'btn-primary': dirty,
                }"
                :disabled="professionalRuleModal.saving || !dirty"
                @click="saveProfessional">
          <fa-icon :icon="['fal', 'save']"/>
        </button>
      </div>
    </div>
    <dx-modal :title="`Regras de ${professional.name}`"
              v-model="professionalRuleModal.show" size="sm"
              id="modal-expense-rules">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="notes" class="form-label">Procedimento</label>
          <input type="text" class="form-input text-bold"
                 v-model="expense.name" readonly>
        </div>
        <div class="column col-6 form-group">
          <label for="notes" class="form-label">idade mínima</label>
          <dx-input-number class="form-input text-center" maxlength="2"
                           v-model="professional.age.min" />
        </div>
        <div class="column col-6 form-group">
          <label for="notes" class="form-label">idade máxima</label>
          <dx-input-number class="form-input text-center" maxlength="3"
                           v-model="professional.age.max" />
        </div>
        <div class="column col-12 form-group">
          <label for="notes" class="form-label">Observações</label>
          <textarea id="notes" name="notes" class="form-input" rows="3"
                    v-model="professional.notes" />
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-error float-left"
                :class="{loading: professionalRuleModal.deleting}"
                :disabled="professionalRuleModal.deleting || professionalRuleModal.saving"
                @click="removeProfessional">
          Remover profissional
        </button>
        <button class="btn btn-primary mr-1"
                :class="{loading: professionalRuleModal.saving}"
                :disabled="professionalRuleModal.saving || professionalRuleModal.deleting"
                @click="saveProfessional">Salvar</button>
        <button class="btn" @click="professionalRuleModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>

export default {
  props: {
    idx: {
      type: Number,
    },
    partnerExpenseId: {
      type: String,
    },
    expense: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      dirty: false,
      professional: this.item,
      professionalRuleModal: {
        show: false,
        saving: false,
        deleting: false,
      },
    };
  },
  watch: {
    'professional.price': {
      handler() {
        this.dirty = true;
      },
      deep: true,
    },
  },
  methods: {
    openProfessional() {
      this.professionalRuleModal.show = true;
    },
    saveProfessional() {
      this.professionalRuleModal.saving = true;
      const data = {
        professionalId: this.professional.id,
        specialtyCode: this.professional.specialty.code,
        age: this.professional.age,
        price: this.professional.price,
        notes: this.professional.notes,
      };

      this.$http.put(`/partner-expenses/${this.partnerExpenseId}/professionals`, data)
        .then(() => {
          this.dirty = false;
          this.professionalRuleModal.show = false;
        })
        .catch(() => {})
        .then(() => {
          this.professionalRuleModal.saving = false;
        });
    },
    removeProfessional() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este profissional?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.professionalRuleModal.deleting = true;
              const params = {
                professionalId: this.item.id,
                specialtyCode: this.item.specialty.code,
              };
              this.$http
                .delete(`/partner-expenses/${this.partnerExpenseId}/professionals/`, { params })
                .then(() => {
                  close();
                  this.professionalRuleModal.show = false;
                  this.$emit('removeProfessional', {
                    professionalId: this.item.id,
                    specialtyCode: this.item.specialty.code,
                  });
                })
                .catch(() => {
                  this.$toast.show('Ocorreu um erro', { type: 'error' });
                })
                .then(() => {
                  this.professionalRuleModal.deleting = false;
                });
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-expense-professional-item {
  }
</style>
