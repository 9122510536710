<template>
  <div class="partner-professional-page">
    <div class="empty mt-2" v-if="items.length === 0">
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
      </div>
      <p class="empty-title h5">Profissionais</p>
      <p class="empty-subtitle">
        Nenhum profissional cadastrado. Clique no botão abaixo para cadastrar.
      </p>
      <button class="btn btn-primary btn-icon-left mt-2" @click="open">
        <fa-icon :icon="['fal', 'plus-circle']" />
        Adicionar novo profissional
      </button>
    </div>
    <template v-else>
      <button class="btn btn-primary btn-icon-left mt-2" @click="open">
        <fa-icon :icon="['fal', 'plus-circle']" />
        Adicionar novo profissional
      </button>
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th width="60px">#</th>
          <th>Nome</th>
          <th>Especialidade</th>
          <th>Conselho</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="i">
          <td>{{ i + 1 }}</td>
          <td>
            <a class="c-hand" @click="edit(item.id)">
              {{ item.name }}
            </a>
          </td>
          <td>
            <span v-if="item.specialtyNames.length > 0">
              {{ item.specialtyNames[0] }}
              <span
                v-if="item.specialtyNames.length > 1"
                class="label text-primary tooltip hover-link ml-1 "
                :data-tooltip="item.specialtyNames.slice(1).join('\n')">
                +{{ item.specialtyNames.length -1 }}
              </span>
            </span>
          </td>
          <td>
            <span v-if="item.council">{{ council.formatCouncil(item.council) }}</span>
          </td>
          <td class="text-right">
            <button class="btn btn-sm btn-action btn-icon btn-primary mr-1"
                    :class="{loading: editing}" :disabled="editing || deleting"
                    @click="edit(item.id)">
              <fa-icon :icon="['fal', 'pencil']"/>
            </button>
            <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                    data-tooltip="Excluir" :disabled="editing || deleting"
                    @click="remove(item, i)">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <dx-modal title="Cadastro do profissional"
              v-model="show" id="modal-partner-professional">
      <div class="partner-professional">
        <div class="columns form-group">
          <div class="column col-4 col-md-6 col-sm-12"
               :class="{'has-error': $v.form.identity.value.$error}">
            <label for="professional-cpf" class="form-label">CPF</label>
            <div class="has-icon-right">
              <input type="text" id="professional-cpf"
                     v-model="form.identity.value"
                     :disabled="form.id !== ''"
                     @change="loadEntityCPF"
                     @blur="$v.form.identity.value.$touch()"
                     class="form-input" autocomplete="nope"
                     placeholder="000.000.000-00" v-mask-cpf>
              <i class="form-icon" :class="{loading: loading}"></i>
            </div>
            <template v-if="$v.form.identity.value.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.identity.value.required">
                Campo obrigatório
              </div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.identity.value.cpf">CPF inválido</div>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-6 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="professional-name" class="form-label">Nome</label>
            <input type="text" id="professional-name" name="professional-name"
                   v-model="form.name" class="form-input"
                   @blur="$v.form.name.$touch()"
                   :disabled="form.id !== ''"
                   placeholder="Nome" autocomplete="nope">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.birthDate.$error}">
            <label class="form-label">Nascimento</label>
            <dx-input-date
              id="professional-birth-date"
              class="form-input"
              v-model="form.birthDate"
              :disabled="form.id !== ''"
              @blur="$v.form.birthDate.$touch()"
            />
            <template v-if="$v.form.birthDate.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.birthDate.required">Campo obrigatório</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.birthDate.date">Data inválida</div>
              <div class="form-input-hint"
                   v-else-if="!$v.form.birthDate.maxDate">Data inválida</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.gender.$error}">
            <label for="professional-gender" class="form-label">Sexo</label>
            <select id="professional-gender" name="professional-gender"
                    v-model="form.gender"
                    :disabled="form.id !== '' && !!form.gender"
                    class="form-select" @change="$v.form.gender.$touch()">
              <option value="">[Selecione]</option>
              <option value="female">Feminino</option>
              <option value="male">Masculino</option>
            </select>
            <template v-if="$v.form.gender.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.gender.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.cellphone.$error}">
            <label for="professional-cellphone" class="form-label">Celular</label>
            <input type="text" id="professional-cellphone"
                   v-model="form.cellphone" class="form-input"
                   autocomplete="nope"
                   @blur="$v.form.cellphone.$touch()"
                   placeholder="(00) 0 0000-0000" v-mask-phone.br>
            <template v-if="$v.form.cellphone.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.cellphone.phone">Telefone inválido</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.phone.$error}">
            <label for="professional-phone" class="form-label">Telefone</label>
            <input type="text" id="professional-phone" name="professional-phone"
                   v-model="form.phone" class="form-input" autocomplete="nope"
                   @blur="$v.form.phone.$touch()"
                   placeholder="(00) 0 0000-0000" v-mask-phone.br>
            <template v-if="$v.form.phone.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.phone.phone">Telefone inválido</div>
            </template>
          </div>
          <div class="column col-6 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.email.$error}">
            <label for="professional-email" class="form-label">Email</label>
            <input type="text" id="professional-email"
                   v-model="form.email"
                   class="form-input" @blur="$v.form.email.$touch()"
                   placeholder="nome@email.com" autocomplete="nope">
            <template v-if="$v.form.email.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.email.email">Email inválido</div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label for="professional-title" class="form-label">Título</label>
            <select id="professional-title" name="professional-title"
                    v-model="form.professional.title"
                    class="form-select">
              <option value="">[Selecione]</option>
              <option value="Sr.">Sr.</option>
              <option value="Sra.">Sra.</option>
              <option value="Dr.">Dr.</option>
              <option value="Dra.">Dra.</option>
            </select>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.professional.council.name.$error}">
            <label for="professional-council" class="form-label">Conselho</label>
            <select id="professional-council" name="professional-council"
                    v-model="form.professional.council.name"
                    @change="$v.form.professional.council.name.$touch()"
                    class="form-select">
              <option value="">[Selecione]</option>
              <option v-for="(value, text) in council.councils"
                      :value="text" :key="text">{{ text }}</option>
            </select>
            <template v-if="$v.form.professional.council.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.professional.council.name.required">
                Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.professional.council.state.$error}">
            <label for="professional-council-state" class="form-label">UF</label>
            <select id="professional-council-state"
                    v-model="form.professional.council.state"
                    @change="$v.form.professional.council.state.$touch()"
                    class="form-select">
              <option value="">[Selecione]</option>
              <option v-for="(text, value) in states"
                      :value="value" :key="value">{{ value }}</option>
            </select>
            <template v-if="$v.form.professional.council.state.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.professional.council.state.required">
                Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.professional.council.record.$error}">
            <label for="professional-council-record"
                   class="form-label">Registro</label>
            <input type="text" id="professional-council-record"
                   name="professional-council-record"
                   @change="$v.form.professional.council.record.$touch()"
                   v-model="form.professional.council.record" class="form-input"
                   autocomplete="nope" placeholder="00000">
            <template v-if="$v.form.professional.council.record.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.professional.council.record.required">
                Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.professional.specialties.$error}">
            <label for="professional-name" class="form-label">Especialidades</label>
            <select id="professional-specialties"
                    v-model="specialtyCode" @change="addSpecialty"
                    class="form-select">
              <option value="">[Selecione]</option>
              <option v-for="(item, i) in filteredSpecialties"
                      :value="item.code" :key="i">{{ item.name }}</option>
            </select>
            <template v-if="$v.form.professional.specialties.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.professional.specialties.required">
                Campo obrigatório
              </div>
            </template>
            <div class="chip bg-secondary mt-2"
                 v-for="(item, i) in form.professional.specialties"
                 :key="item.code">
              <span>{{ item.name }}</span>
              <button class="btn btn-clear" aria-label="Close"
                      @click="deleteSpecialty(i)"/>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary btn-icon-left mr-1"
                :class="{loading: saving}" :disabled="saving"
                @click="save">
          <fa-icon :icon="['fal', 'save']"/>
          Salvar
        </button>
        <button class="btn" @click="show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { required, email } from 'vuelidate/src/validators';
import * as council from '@/data/council-types';
import { cpf, phone, maxDate } from 'src/data/validators';
import states from 'src/data/states';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  props: {
    partnerId: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      editing: false,
      deleting: false,
      saving: false,
      items: [],
      council,
      states,
      specialtyCode: '',
      specialties: [],
      image: this.blankImageForm(),
      form: this.blankForm(),
    };
  },
  computed: {
    filteredSpecialties() {
      return this.specialties
        .filter(item => this.form.professional.specialties
          .every(specialty => specialty.code !== item.code));
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        birthDate: { required, maxDate: maxDate() },
        gender: { required },
        phone: { phone },
        cellphone: { phone },
        email: { email },
        identity: {
          value: { required, cpf },
        },
        professional: {
          council: {
            name: { required },
            state: { required },
            record: { required },
          },
          specialties: { required },
        },
      },
    };
  },
  mounted() {
    this.load();
    this.loadSpecialties();
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http.get(`/partners/${this.partnerId}/professionals`)
        .then(({ data }) => {
          this.data = data;
          this.items = data.items;
          data.items = data.items.map((item) => {
            item.specialtyNames = item.specialties
              .map(({ name }) => name);
            return item;
          });
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    loadSpecialties() {
      const params = {
        limit: 0,
        isSpecialty: true,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => {
          this.specialties = data.items;
        })
        .catch(() => {
          this.$toast.error('Erro ao carregar as especialidades');
        });
    },
    loadEntityCPF() {
      if (this.form.identity.value.trim() === '') {
        return;
      }
      const identity = this.onlyNumbers(this.form.identity.value);

      const params = {
        relType: 'professional',
      };

      this.$http
        .get(`/entities/${identity}`, { params })
        .then(({ data }) => {
          const professional = data.professional
            ? data.professional : null;
          const professionalCouncil = professional
            && professional.councils
            && professional.councils.length > 0
            ? data.professional.councils[0] : null;

          this.form.id = data.id;
          this.form.name = data.name;
          this.form.birthDate = data.birthDate;
          this.form.gender = data.gender;
          this.form.phone = data.phone;
          this.form.cellphone = data.cellphone;
          this.form.email = data.email;
          this.form.professional = {
            title: professional.title,
            council: professionalCouncil,
            specialties: professional.specialties,
          };
        })
        .catch(() => {});
    },
    open() {
      this.$v.form.$reset();
      this.form = this.blankForm();
      this.show = true;
    },
    edit(id) {
      this.editing = true;
      return this.$http.get(`/partners/${this.partnerId}/professionals/${id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
          this.show = true;
        })
        .catch()
        .finally(() => {
          this.editing = false;
        });
    },
    remove(item, i) {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este profissional?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$http
                .delete(`/partners/${this.partnerId}/professionals/${item.id}`)
                .then(() => {
                  close();
                  this.$emit('removeProfessional');
                  this.items.splice(i, 1);
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro ao excluir');
                });
            },
          },
        ],
      });
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.saving = true;
      const data = this.clone(this.form);

      this.$http
        .post(`/partners/${this.partnerId}/professionals`, data)
        .then(() => {

        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar esse cadastro');
        })
        .finally(() => {
          this.load();
          this.saving = false;
          this.show = false;
        });
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        if (e.target.files[0].size > 5120000) {
          this.$toast.show('A imagem não pode ser maior que 5MB', { type: 'error' });
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    removeImage() {
      this.image = this.blankImageForm();
      this.form.imageUrl = null;
      // if (!this.form.id) {
      //   return;
      // }
      // this.$http
      //   .delete(`/entities/${this.professional.form.id}/images`)
      //   .catch((error) => {
      //     this.$toast.error('Não foi possível remover imagem do profissional');
      //   });
    },
    addSpecialty() {
      if (!this.specialtyCode) {
        return;
      }

      const specialty = this.specialties
        .find(item => item.code === this.specialtyCode);

      if (specialty) {
        this.form.professional.specialties
          .push({ code: specialty.code, name: specialty.name });
      }
      this.specialtyCode = '';
    },
    deleteSpecialty(i) {
      this.form.professional.specialties.splice(i, 1);
    },
    blankImageForm() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    blankForm() {
      return {
        id: '',
        name: '',
        birthDate: '',
        gender: '',
        phone: '',
        cellphone: '',
        email: '',
        identity: {
          type: 'cpf',
          value: '',
        },
        professional: {
          title: '',
          council: {
            name: '',
            state: '',
            record: '',
          },
          specialties: [],
        },
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .partner-professional-page {
  }
  #modal-partner-professional {
    display: flex;
    .partner-professional-side {
      margin-right: $layout-spacing-lg;
    }

    .partner-professional-image {
      margin-top: $layout-spacing;
      background: $gray-color-light center no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      height: 5rem;
      width: 5rem;
      &:not(.show-options):hover {
        .partner-professional-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .partner-professional-image-options {
          opacity: 1;
        }
      }
    }
    .partner-professional-image-options {
      align-items: center;
      background-color: $gray-color-light;
      color: red;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
  }
</style>
